import Vue from 'vue'
import VueRouter from 'vue-router'
import ContentBoard from "@/components/ContentBoard.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'ContentBoard'
    }
  },
  {
    path: '/main',
    name: 'ContentBoard',
    component: ContentBoard,
    meta: {
      layout: 'default',
      title: ''
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
