<template>
  <article class="content--wrap content-box"
           :class="{type2: isDimmedContent}"
           :style="{ width: `${width}`, height: `${height}`, minWidth: `${minWidth}`, minHeight: `${minHeight}` }">
    <slot></slot>
  </article>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
      required: false
    },
    height: {
      type: String,
      default: 'auto',
      required: false
    },
    minWidth: {
      type: String,
      default: '1px',
      required: false
    },
    minHeight: {
      type: String,
      default: '1px',
      required: false
    },
    isDimmedContent: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.content--wrap {
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 0 23px 15px -12px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;

  .type2 {
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.16);
  }
}
</style>
