<template>
  <div class="alert">
    <i class="icon"
       :class="icon"></i>
    <h3 class="title"
        v-if="title"
        v-html="title"></h3>
    <p class="description"
        v-if="description"
        v-html="description"></p>
    <input type="file"
           :style="{width: '0px', height: '0px'}"
           ref="file"
           accept="*"
           @change="readFile">
    <div>{{selectedFile.name}}</div>
    <ui-button v-if="video && title !== 'GPT 분석 결과'"
               @click="videoUpload">
      <span>파일 선택</span>
    </ui-button>
    {{gpt}}
    <div class="buttons">
      <ui-button v-if="cancel"
                 @click="$emit('onCancel')">
        <span v-html="cancel"></span>
      </ui-button>
      <ui-button v-if="submit"
                 :primary2="true"
                 @click="onSubmit">
        <span v-html="submit"></span>
      </ui-button>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'

export default {
  name: 'AlertModal',
  components: {
    UiButton
  },
  props: {
    icon: {
      type: String,
      default: 'icon--alert'
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    cancel: {
      type: String
    },
    submit: {
      type: String
    },
    video: {
      type: Boolean
    },
    gpt: {
      type: String
    }
  },
  methods: {
    onSubmit() {
      if (this.video) {
        this.$emit('onSubmit', this.selectedFile)
      } else {
        this.$emit('onSubmit')
      }
    },
    videoUpload() {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    readFile(event) {
      this.selectedFile = event.target.files[0]
    }
  },
  data() {
    return {
      selectedFile: ''
    }
  }
}

</script>

<style lang="scss" scoped>
.alert {
  width: 430px;
  border-radius: 14px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  text-align: center;
  padding-top: 50px;

  .title {
    margin: 11px 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    color: #444;
  }

  .description {
    margin: 0 20px;
    font-size: 14px;
    line-height: 1.5;
    color: #666666;

  }

  .buttons {
    padding: 40px 20px 25px;

    .ui-btn {
      min-width: 90px;
      margin: 0 2px 0 3px;
    }
  }
}
</style>
