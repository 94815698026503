<template>
  <button
    class="ui-btn"
    :type="buttonType"
    :class="[cssClass, getBrowserInfo, getPlatformInfo, iconName ? 'use-icon' : 'no-icon']"
    :disabled="disabled"
    @click="onClick">
    <i v-if="iconName" class="icon" :class="iconName"/>
    <span v-if="!hideLabel" class="text" :class="$i18n.locale"><slot/></span>
    <span class="loading-circle">
      <svg x="0px" y="0px" viewBox="0 0 150 150">
        <circle class="loading-inner" cx="75" cy="75" r="60"/>
      </svg>
    </span>
  </button>
</template>

<script>
import { browserInfo } from '@/libs/utils'

export default {
  name: 'UiButton',
  props: {
    buttonType: {
      type: String,
      default: 'button'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    primary2: {
      type: Boolean,
      default: false
    },
    primary3: {
      type: Boolean,
      default: false
    },
    primary4: {
      type: Boolean,
      default: false
    },
    primary5: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal'
    },
    iconName: {
      type: String,
      required: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      required: false
    },
    replace: {
      type: Boolean,
      default: false
    },
    go: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      iconSize: null,
      clicked: false
    }
  },
  computed: {
    cssClass () {
      const value = {
        loading: !!this.loading,
        disabled: !!this.disabled,
        primary: !!this.primary,
        primary2: !!this.primary2,
        primary3: !!this.primary3,
        primary4: !!this.primary4,
        primary5: !!this.primary5,
        'no-border': !!this.noBorder,
        circle: !!this.circle
      }
      value[`size--${this.size}`] = true
      return value
    },
    getBrowserInfo () {
      return 'browser-' + browserInfo.name
    },
    getPlatformInfo () {
      return 'platform-' + browserInfo.platform
    }
  },
  mounted () {
    const iconEl = this.$el.querySelector('icon')

    if (iconEl) {
      this.iconSize = {
        width: iconEl.offsetWidth,
        height: iconEl.offsetHeight
      }
    }
  },
  methods: {
    onClick (event) {
      if (!this.clicked) {
        this.clicked = true
        setTimeout(() => {
          this.clicked = false
        }, 500)
        if (this.disabled) {
          return
        }

        if (this.go) {
          this.$router.go(this.go)
        } else if (this.to) {
          if (this.replace) {
            this.$router.replace(this.to)
          } else {
            this.$router.push(this.to)
          }
        }
        this.$emit('click', event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-btn {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #bbb;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  color: #444;
  transition: background-color 0.2s ease,
              border-color 0.2s ease,
              opacity 0.1s ease,
              color 0.2s ease;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.06);
    transition: opacity 0.2s ease;
    opacity: 0;
    border-radius: 30px;
  }

  .loading-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 55%;
    animation: loading 3s linear infinite;
    transition: opacity 0.3s ease;
    opacity: 0;

    svg {
      height: 100%;
    }

    .loading-inner {
      stroke: {
        dashoffset: 0;
        dasharray: 300;
        width: 20;
        miterlimit: 10;
        linecap: round;
      }

      animation: loading-circle 2s linear infinite;
      stroke: #EC0047;
      fill: transparent;
    }
  }

  @keyframes loading {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes loading-circle {
    0% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: -600;
    }
  }

  .text {
    display: inline-block;
    transition: opacity 0.3s ease;

    &.en {
      transform: translateY(0.5px);
    }
  }

  .icon {
    transition: opacity 0.3s ease;
  }

  &.loading {
    .loading-circle {
      opacity: 1;
    }

    .text {
      opacity: 0;
    }

    .icon {
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }

  &:hover {
    & > .text {
      z-index: 1;
    }
    &:after {
      opacity: 1;
    }
  }

  &:active {
    & > .text {
      z-index: 1;
    }
    &:after {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }

  &:not(.primary) {
    &.disabled {
      opacity: 0.2;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.primary {
    border: 0;
    background-color: #EC0047;

    .loading-circle .loading-inner {
      stroke: #ffffff;
    }

    &:hover {
      &:after {
        background-color: rgba(0, 0, 0, 0.16);
      }
    }

    &:active {
      &:after {
        background-color: rgba(0, 0, 0, 0.32);
      }
    }

    &.disabled {
      background-color: rgba(0, 0, 0, 0.16) !important;

      &:not(.loading) > .text,
      &:not(.loading) > .icon {
        opacity: 0.85;
      }

      .loading-circle .loading-inner {
        stroke: #EC0047;
      }
    }

    & > .text {
      color: #fff;
    }
  }

  &.primary2 {
    border-color: #EC0047;
    background-color: #fff;

    .loading-circle .loading-inner {
      stroke: #EC0047;
    }

    &:after {
      opacity: 0;
    }

    &:hover {
      background-color: #EC0047;
      & > .text {
        color: #fff;
      }
    }

    &:active {
      background-color: #EC0047;
      &:after {
        background-color: rgba(0, 0, 0, 0.16);
      }
      & > .text {
        color: #fff;
      }

      .loading-circle .loading-inner {
        stroke: #ffffff;
      }
    }

    & > .text {
      color: #EC0047;
    }
  }

  &.primary3 {
    border-color: #bbb;
    background-color: #fff;

    .loading-circle .loading-inner {
      stroke: #EC0047;
    }

    &:hover {
      &:after {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    &:active {
      &:after {
        background-color: rgba(0, 0, 0, 0.16);
      }
    }

    .text {
      color: #444;
    }
  }

  &.primary4 {
    border-color: #1199af;
    background-color: #1199af;

    .loading-circle .loading-inner {
      stroke: #ffffff;
    }

    &:hover {
      &:after {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    &:active {
      &:after {
        background-color: rgba(0, 0, 0, 0.16);
      }
    }

    .text {
      color: #fff;
    }
  }

  &.primary5 {
    border-color: #b1b1b1;
    background-color: transparent;

    .loading-circle .loading-inner {
      stroke: #b1b1b1;
    }

    &:hover {
      &:after {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &:active {
      &:after {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .text {
      color: #b1b1b1;
    }
  }

  &.no-border {
    border: none;
  }

  &.size--xlarge {
    min-width: 100px;
    height: 44px;
    border-radius: 30px;
    padding: 0 30px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;

    &.use-icon:not(.circle) {
      padding-left: 55px;
      padding-right: 30px;
      & > .icon {
        left: 15px;
      }

      & > .text {
        font-size: 16px;
      }
    }
  }

  &.size--large {
    min-width: 100px;
    height: 38px;
    border-radius: 20px;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
  }

  &.size--normal {
    border-radius: 20px;
    height: 38px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 500;
    min-width: 70px;

    &.circle {
      width: 38px;
      min-width: auto;
      padding: 0;
    }
  }

  &.size--small {
    border-radius: 20px;
    height: 34px;
    padding: 0 13px;
    min-width: 60px;

    &.use-icon:not(.circle) {
      padding-left: calc(11px + 16px + 5px);
      & > .text {
        font-size: 13px;
      }
    }

    &.circle {
      width: 34px;
      min-width: auto;
      padding: 0;
    }
  }

  & > .text {
    position: relative;
    top: -1px;
  }

  & > .icon {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
    z-index: 1;
  }

  &.use-icon:not(.circle) {
    padding: 0;
    padding-left: 35px;
    padding-right: 13px;
    text-align: right;

    .loading-circle {
      left: calc(50% - 12px);
    }

    & > .text {
      margin-left: 0;
    }
  }

  &.use-icon.circle {
    & > .icon {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
