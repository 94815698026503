import { addDays, addHours, addMinutes, isBefore } from 'date-fns'
import httpClient from './http-client'

let cacheSet = {}
const baseUrl = process.env.VUE_APP_API_BASE_URL || 'http://localhost:5050'

function isApiUrl (url) {
  if (url.charAt(0) === '/') {
    return true
  }
  return url.indexOf(baseUrl) === 0
}

function getObjectKey (obj) {
  const key = Object.entries(obj).map(([key, value]) => {
    return `${key}=${value}`
  }).join('&')
  return key
}

function getCacheKey (config) {
  const method = config.method || 'get'
  const cacheKeys = [method, config.url]

  if (config.params) {
    cacheKeys.push(getObjectKey(config.params))
  }
  if (config.data) {
    cacheKeys.push(getObjectKey(config.data))
  }
  const key = cacheKeys.join('|')
  return key
}

function getTtlDate (ttl) {
  const ttlRegex = /^\d+[mMhHdD]$/

  if (!ttlRegex.test(ttl)) {
    console.error(`invalid ttl: ${ttl}`)
    return null
  }
  const now = new Date()
  const value = parseInt(ttl, 10)
  const unit = ttl.charAt(ttl.length - 1).toLowerCase()

  switch (unit) {
    case 'm':
      return addMinutes(now, -value)
    case 'h':
      return addHours(now, -value)
    case 'd':
      return addDays(now, -value)
    default:
      return null
  }
}

function setCache (key, data) {
  cacheSet[key] = {
    data,
    date: new Date()
  }
}

function getCache (key, ttl) {
  const cache = cacheSet[key]
  const ttlDate = getTtlDate(ttl)

  if (cache) {
    if (isBefore(cache.date, ttlDate)) {
      delete cacheSet[key]
    } else {
      return cache.data
    }
  }
  return null
}

export function request (config, force = false, ttl = '3m') {
  const isApiRequest = isApiUrl(config.url)

  if (!isApiRequest) {
    return httpClient(config)
  } else if (force) {
    if (!config.params) {
      config.params = {}
    }
    config.params.t = new Date().getTime()
  }

  const key = getCacheKey(config)

  if (!force && ttl) {
    const cache = getCache(key, ttl)

    if (cache) {
      cache.cache = true
      return Promise.resolve(cache)
    }
  }

  return httpClient(config).then(response => {
    const data = response.data
    setCache(key, Array.isArray(response.data) ? [...response.data] : { ...response.data })
    if (data && typeof data === 'object' && config.method !== 'get') {
      data.cache = false
    }
    return data
  })
}
