function getBrowserInfo () {
  const uaContains = (str) => window.navigator.userAgent.indexOf(str) !== -1
  let name = null
  let platform = null

  if (uaContains('Windows')) {
    platform = 'window'

    if (uaContains('MSIE') || uaContains('Trident/')) {
      name = 'ie'
    }
  }

  if (uaContains('Firefox')) {
    name = 'firefox'
  }

  if (uaContains('Chrome')) {
    name = 'chrome'
  }

  if (uaContains('Safari') && !uaContains('Chrome')) {
    name = 'safari'
  }

  if (uaContains('Edg')) {
    name = 'edge'
  }

  return {
    name,
    platform
  }
}

export const browserInfo = getBrowserInfo()

// https://codepen.io/code_monk/pen/FvpfI
