<template>
  <div class="content-board">
    <div class="chart-type">
      <button class="tab-btn" :class="{on: this.currentTab === 'setting'}"
              @click="onSelectTab('setting')">
        {{ $t('testSetting.tabTitle') }}
      </button>
      <button class="tab-btn" :class="{on: this.currentTab === 'result'}"
              @click="onSelectTab('result')">
        {{ $t('testResult.tabTitle') }}
      </button>
    </div>
    <test-setting v-if="currentTab === 'setting'"/>
    <test-result v-else-if="currentTab === 'result'"/>
  </div>
</template>

<script>
import TestResult from "@/components/TestResult.vue";
import TestSetting from "@/components/TestSetting.vue";
export default {
  name: 'ContentBoard',
  components: {
    TestSetting,
    TestResult
  },
  computed: {
  },
  created () {
  },
  methods:{
    onSelectTab (value) {
      this.currentTab = value
    },
  },
  data() {
    return {
      currentTab: 'setting', //setting, result
    }
  }
}
</script>

<style lang="scss" scoped>
.content-board {
  padding: 30px;
  padding-top: 0px;
  min-width: 1248px;

  .chart-type {
    position: relative;
    border-bottom: 1px solid #D1D1D1;

    .tab-btn {
      padding: 7px 3px;
      font-weight: 700;
      font-size: 18px;
      margin: 0 15px;
      color: #999999;

      &.on {
        color: #2f2f2f;
        border-bottom: 3px solid #ec0147;
      }

      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

</style>
