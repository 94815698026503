import { STORAGE_KEY } from './constants'

const ACCESS_TOKEN = 'access_token'

export function getAuthToken () {
  return localStorage.getItem(ACCESS_TOKEN) || null
}

export function removeAuthToken () {
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(STORAGE_KEY.devTimestamp)
}

