import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import init from './libs/init'



init().then(i18n => {
  window.__VUE_ROOT__ = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})

