<template>
  <div class="test-result">
    <content-box :min-height="'646px'">
      <ocr-list
          class="ocr-list"
          :ocrList="ocrList"
          @clickResult="clickResult">
      </ocr-list>
      <test-result-list
          class="test-list"
          :imgDataId="testResultList.id"
          :testResultList="testResultList">
      </test-result-list>
    </content-box>
  </div>
</template>

<script>
import OcrList from "@/components/OcrList.vue";
import TestResultList from "@/components/TestResultList.vue";
import {getResultData, getResultList} from "@/api/probe";
import ContentBox from "@/components/ui/ContentBox.vue";
export default {
  name: 'TestResult',
  components: {
    ContentBox,
    TestResultList,
    OcrList,
  },
  created () {
    this.getResultList()
  },
  computed: {
  },
  methods: {
    load() {
    },
    clickResult (dataId) {
      const param = {
        'id': dataId
      }
      getResultData(param).then((resp) => {
        this.testResultList = resp.data.result

      })
    },
    getResultList () {
      getResultList().then((resp) => {
        this.ocrList = resp.data.result
      })
    }
  },
  data () {
    return {
      ocrList: [],
      testResultList: {},
    }
  }
}
</script>

<style scoped lang="scss">
.test-result {
  margin-top: 20px;
  margin-bottom: 20px;
  .ocr-list {
    display: inline-block;
    vertical-align: top;
    width: 350px;
  }
  .test-list {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 350px);
  }
}
</style>
