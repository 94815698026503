var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test-list"},[_c('div',{staticClass:"list-body"},[_c('div',{staticClass:"data-wrap"},[_c('div',{ref:`dataImage-${_vm.testResultList.id}`,staticClass:"data-image"},[_c('img',{attrs:{"src":_vm.testResultList.imagePath,"alt":""}}),(_vm.testResultList.ocrResult)?_c('div',{staticClass:"data-ocr"},_vm._l((_vm.ocrResult),function(ocr,idx){return _c('div',{key:`ocr-${idx}`,staticClass:"sentence",style:({
                   width: (ocr[2]  * _vm.imageScale[_vm.testResultList.id]) + 'px',
                   height: (ocr[3] * _vm.imageScale[_vm.testResultList.id]) + 'px',
                   top: (ocr[1] * _vm.imageScale[_vm.testResultList.id]) + 'px',
                   left: (ocr[0] * _vm.imageScale[_vm.testResultList.id]) + 'px'
                 }),attrs:{"title":ocr.text}})}),0):_vm._e()])]),(_vm.testResultList.ocrResult.data)?_c('div',[_c('table',{staticClass:"ocr-table"},[_vm._m(0),_vm._m(1),_c('tbody',_vm._l((_vm.ocrResult),function(ocr,idx){return _c('tr',{key:`crop-${idx}`},[_c('td',[_vm._v(_vm._s(Object.keys(_vm.ocrResult).indexOf(idx) + 1))]),_c('td',[_c('crop-image',{ref:`cropImage-${_vm.testResultList.id}`,refInFor:true,attrs:{"url":_vm.testResultList.imagePath,"imageWidth":1920,"imageHeight":1080,"width":ocr[2],"height":ocr[3],"offset":{ x: ocr[0], y: ocr[1] },"maxWidth":300,"maxHeight":100}})],1),_c('td',[_vm._v(_vm._s(ocr[6]))])])}),0)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"10%"}}),_c('col'),_c('col')])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Id")]),_c('th',[_vm._v("Image")]),_c('th',[_vm._v("text")])])])
}]

export { render, staticRenderFns }