<template>
  <div class="test-setting">
    <div class="info-line">
      <content-box class="info-box" :height="'65px'">

        <div class="test-count">

          <div class="select-count">
            <div class="data" :class="'state'">
              <svg v-if="status !== '대기 중' && status !== 'OCR 작업 완료.' && status !== '비디오 분할 캡쳐 업로드 완료'" class="circular-loader" viewBox="25 25 50 50" >
                <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#70c542" stroke-width="2" />
              </svg>
              <div v-if="status === 'OCR 작업중...' || status === 'OCR 작업 완료.'" class="data-text">
                {{ocrImage.fileName}} 이미지 {{status}}
              </div>
              <div v-else class="data-text">
                {{status}}
              </div>
            </div>
          </div>
        </div>
      </content-box>
      <div class="btn-run">
        <ui-button
            :size="'large'"
            :primary="true"
            @click="runClick">{{ runCheck ? $t('testSetting.running') : $t('testSetting.runButton') }}</ui-button>
      </div>
    </div>
    <content-box class="table-wrap">
      <div class="thead">
        <table>
        <colgroup>
          <col>
          <col style="width: 10.5%">
          <col>
        </colgroup>
        <thead>
          <tr>
            <th class="gap"></th>
            <th v-for="column in useHeaders" :key="`${column.value}`"
                :class="`th-${column.value}`"
                @click="changeSort(column.value)">
              {{column.name}}
            </th>
            <th class="gap"></th>
          </tr>
        </thead>
      </table>
      </div>
      <div class="tbody">
        <table>
          <tbody>
          <tr v-for="item in testList" :key="item.id">
            <td class="gap"></td>
            <td style="width: 99%"><div class="td-data"><a :href="item.fileLink"><span class="label label-danger">{{item.fileName}}</span></a></div></td>
            <td>        <ui-button
                :primary="true"
                @click="ocrClick(item)">분석</ui-button></td>
            <td class="gap"></td>
          </tr>
        </tbody>
        </table>
      </div>
    </content-box>

    <Dimmed v-if="runAlert == true">
      <alert :icon="'icon--alert'"
             :title="$t('testSetting.runAlert.title')"
             :submit="$t('common.confirm')"
             :cancel="$t('common.cancel')"
             :video="true"
             @onSubmit="alertSubmit"
             @onCancel="alertCancel"/>
    </Dimmed>
    <Dimmed v-if="runAlert == 'ocr'">
      <alert :icon="'icon--alert'"
             :title="'GPT 분석 결과'"
             :gpt="gptAns"
             :submit="$t('common.confirm')"
             :cancel="$t('common.cancel')"
             :video="true"
             @onSubmit="alertSubmit"
             @onCancel="alertCancel"/>
    </Dimmed>
  </div>

</template>


<script>
import ContentBox from "@/components/ui/ContentBox.vue"
import UiButton from "@/components/ui/UiButton.vue"
import Alert from "@/components/ui/Alert.vue"
import Dimmed from "@/components/ui/Dimmed.vue";
import {STATUS_MAP, STATUS_STOP} from "@/libs/constants"
import {
  ocrRunCheck,
  splitVideo,
  ocrSplitCheck,
  uploadImg,
  toGPT,
  getResultList, getResultData
} from "@/api/probe"
const StorageFactory = require('../libs/storage-factory')

export default {
  name: 'TestSetting',
  components: {
    Dimmed,
    Alert,
    ContentBox,
    UiButton
  },
  created () {
    this.status = STATUS_STOP
    this.callRunCheck()
    this.load()
  },
  computed: {
    getOcrImageData () {
      return this.ocrImage
    },
    order () {
      return !this.listOption.sort.includes('-')
    },
    computedSort () {
      return this.listOption.sort.replace('-', '')
    }
  },
  methods: {
    ocrClick(data) {
      console.log(data)
      this.runAlert = 'ocr'
      getResultData({id: data.id}).then(resp => {
        this.gptAns = resp.data.result.answer
      })
      // this.status = STATUS_MAP['101']
      // this.ocrImage = data
      // window.scrollTo(0,0)
      // runImgOcr(data).then((resp) => {
      //   this.callRunCheck(resp.data.result)
      // })
    },
    changeSort (sort) {
      if (sort !== 'description') {
        if (this.listOption.sort === '-' + sort) {
          this.listOption.sort = sort
        } else {
          this.listOption.sort = '-' + sort
        }
        this.sortInList()
      }
    },
    sortInList () {
      this.testList.sort((a, b) => {
        if (typeof a[this.computedSort] === 'string' && typeof b[this.computedSort] === 'string') {
          if (this.order) {
            // return a[this.computedSort].toString().localeCompare(b[this.computedSort].toString())
            return new Intl.Collator('en').compare(a[this.computedSort].toString(), b[this.computedSort].toString())
          } else {
            // return b[this.computedSort].toString().localeCompare(a[this.computedSort].toString())
            return new Intl.Collator('en').compare(b[this.computedSort].toString(), a[this.computedSort].toString())
          }
        } else {
          if (this.order) {
            return a[this.computedSort] > b[this.computedSort] ? 1 : -1
          } else {
            return b[this.computedSort] > a[this.computedSort] ? 1 : -1
          }
        }
      })
    },
    alertSubmit(fileData) {
      this.status = STATUS_MAP['1']
      const storage = StorageFactory.getInstance('aws-s3', 1, 'ocr-video')
      storage.putObject(fileData.name, fileData, true).then(resp => {
        console.log(resp)
        this.status = STATUS_MAP['2']
        setTimeout(() => {
          this.status = STATUS_MAP['3']
          splitVideo({file: fileData.name}).then(resp2 => {
            const pid = resp2.data.result.pid
            const tempTime = resp2.data.result.tempTime
            this.callSplitCheck(pid, tempTime)
          })
        }, 3000)
      })
      this.runAlert = false
    },
    alertCancel() {
      this.runAlert = false
    },
    runClick () {
      this.runAlert = true
    },
    callSplitCheck(pid, tempTime) {
      const params = {pid: pid}
      ocrSplitCheck(params).then((resp) => {
        if (!resp.data.result) {
          this.runCheck = true
        } else {
          this.runCheck = false
        }
        if (this.runCheck) {
          if (!this.interval) {
            this.interval = setInterval(() => {
              this.callSplitCheck(pid, tempTime)
            }, 5000)
          }
        } else {
          clearInterval(this.interval)
          this.interval = null
          this.status = STATUS_MAP['4']
          setTimeout(() => {
            this.status = STATUS_MAP['5']
            uploadImg({tempTime: tempTime}).then(() => {
              this.status = STATUS_MAP['6']
              this.callRunCheck()
              setTimeout(() => {
                this.load()
              }, 3000)
            })
          }, 3000)
        }
      })
    },
    callRunCheck(data) {
      let params = {}
      if (data) {
        params.id = data.id
      } else {
        params.id = null
      }
      ocrRunCheck(params).then((resp) => {

        if (resp.data.result.jsonPath) {
          this.runCheck = false
        } else {
          if (resp.data.result.id) {
            this.runCheck = true
          } else {
            this.runCheck = false
          }
        }
        if (!data) {
          this.ocrImage = resp.data.result
          this.status = STATUS_MAP['101']
        }
        if (this.runCheck) {
          if (!this.interval) {
            this.interval = setInterval(() => {
              this.callRunCheck(data ? data : resp.data.result)
            }, 5000)
          }
        } else {
          clearInterval(this.interval)
          this.interval = null
          this.status = data ? STATUS_MAP['102'] : STATUS_STOP
          if (!resp.data.result.answer) {
            toGPT({key: resp.data.result.fileName, id: resp.data.result.id}).then(resp => {
              console.log("??? : ", resp)
              this.load()
            })
          }
        }
      })
    },
    load() {
      getResultList(true).then((resp) => {
        this.testList = resp.data.result
      })
      this.selectedItem = []
    },
  },
  data () {
    return {
      status: '',
      ocrImage: '',
      interval: null,
      runAlert: false,
      targetList: [],
      selectedItem: [],
      gptAns: '',
      useHeaders: [
        {
          name: '이름',
          // sortable: true,
          value: 'testKey'
        },
        // {
        //   name: this.$t('testSetting.state'),
        //   // sortable: true,
        //   value: 'state'
        // },
        // {
        //   name: this.$t('testSetting.title'),
        //   // sortable: true,
        //   value: 'title'
        // },
        // {
        //   name: this.$t('testSetting.description'),
        //   // sortable: true,
        //   value: 'description'
        // },
        {
          name: this.$t('testSetting.updatedAt'),
          // sortable: true,
          value: 'updatedAt'
        }
      ],
      testList: [],
      runCheck: null,
      listOption: {
        // pageNum: 1,
        // itemsPerPage: 50,
        sort: 'testKey'
      },
    }
  }
}
</script>

<style scoped lang="scss">
.test-setting {
  margin-top: 20px;
  margin-bottom: 20px;

  .info-line {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .info-box{
      margin-right: 30px;
      padding: 17px;
      height: 65px;
      .test-count {
        margin-left: 20px;
        display: flex;
        .select-count {
          font-size: 18px;
          color: #000;
          font-weight: 600;
          padding: 3px;
          .data-text {
            margin-left: 10px;
          }
          .data {
            &.state {
              display: flex;
              color: #1C84FF;
              .circular-loader {
                margin-top: 5px ;
                -webkit-animation: rotate 2s linear infinite;
                animation: rotate 2s linear infinite;
                width: 18px;
                height: 18px;
                -webkit-transform-origin: center center;
                -ms-transform-origin: center center;
                transform-origin: center center;
                //position: absolute;
                //top: 82px;
                //left: 136px;
                //margin: auto;
              }
              .loader-path {
                stroke-dasharray: 150,200;
                stroke-dashoffset: -10;
                -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                stroke-linecap: round;
              }
              @-webkit-keyframes rotate {
                100% {
                  -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
                }
              }

              @keyframes rotate {
                100% {
                  -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
                }
              }
              @-webkit-keyframes dash {
                0% {
                  stroke-dasharray: 1,200;
                  stroke-dashoffset: 0;
                }
                50% {
                  stroke-dasharray: 89,200;
                  stroke-dashoffset: -35;
                }
                100% {
                  stroke-dasharray: 89,200;
                  stroke-dashoffset: -124;
                }
              }
              @keyframes dash {
                0% {
                  stroke-dasharray: 1,200;
                  stroke-dashoffset: 0;
                }
                50% {
                  stroke-dasharray: 89,200;
                  stroke-dashoffset: -35;
                }
                100% {
                  stroke-dasharray: 89,200;
                  stroke-dashoffset: -124;
                }
              }
              @-webkit-keyframes color {
                0% {
                  stroke: rgba(139, 139, 139, 0.54);
                }
                40% {
                  stroke: #505050;
                }
                66% {
                  stroke: #505050;
                }
                80%, 90% {
                  stroke: #505050;
                }
              }
              @keyframes color {
                0% {
                  stroke: #505050;
                }
                40% {
                  stroke: #505050;
                }
                66% {
                  stroke: #505050;
                }
                80%, 90% {
                  stroke: #505050;
                }
              }
            }
          }
        }
      }
    }
    .btn-run {
      padding: 6px;
    }
  }

  .table-wrap {
    .thead {
      height: 55px;
    }

    .tbody {
      position: relative;
      max-height: calc(100% - 68px);
      padding-bottom: 12px;
      overflow: auto;
    }
    table {
      position: relative;
      width: 100%;
      border-spacing: 0px;

      thead {
        tr {
          th {
            height: 39px;
            font-size: 13px;
            font-weight: normal;
            background: #333333;
            color: #ffffff;
            padding: 9px 22px 7px 15px;
            vertical-align: bottom;
            text-align: left;
            line-height: 20px;
            transition: background-color 0.15s ease;
            white-space: nowrap;

            .icon {
              vertical-align: middle;
              margin: -1px 5px 1px 0;
              cursor: pointer;
            }

            &:first-child {
              border-radius: 7px 0 0 7px;
              border-left: 0;
              box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15);
            }

            &.gap {
              padding: 0;
              width: 25px;
              border-bottom: none;
            }

            .sort {
              display: inline-block;
              width: 14px;
              height: 18px;
              padding: 1px 0;
              margin-top: -2px;
              margin-left: 2px;
              vertical-align: middle;
              border-radius: 4px;
              transition: background-color 0.15s ease,
              box-shadow 0.15s ease;

              i.icon {
                float: left;
                opacity: 0.2;
                transition: opacity 0.15s ease;
              }

              &.active {
                &.asc {
                  .icon--sort-asc {
                    opacity: 1;
                  }
                }

                &.desc {
                  .icon--sort-desc {
                    opacity: 1;
                  }
                }
              }

              &.active {
                background-color: #272727;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.146);
              }
            }
            &.th-testKey {
              padding-left: 13px;
            }

            &.th-regDate {
              text-align: right;
            }

            &.hover {
              .sort {
                background-color: #272727;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.146);
              }
            }

            &:last-child {
              border-radius: 0 7px 7px 0;
            }
          }
        }
      }
      tbody {
        tr {
          position: relative;
          height: 1px;

          td {
            height: 100%;
            text-align: left;
            font-size: 13px;
            line-height: 1.31;
            letter-spacing: normal;
            color: #444;
            word-break: break-all;
            border-bottom: 1px solid #ebebeb;
            vertical-align: top;

            .td-data {
              position: relative;
              height: 100%;
              padding: 17px 15px 17px 15px;
              box-sizing: border-box;
              &.state {
                color: #1C84FF;
                .circular-loader {
                  -webkit-animation: rotate 2s linear infinite;
                  animation: rotate 2s linear infinite;
                  width: 18px;
                  height: 18px;
                  -webkit-transform-origin: center center;
                  -ms-transform-origin: center center;
                  transform-origin: center center;
                  position: absolute;
                  top: 15px;
                  left: 60px;
                  margin: auto;
                }
                .loader-path {
                  stroke-dasharray: 150,200;
                  stroke-dashoffset: -10;
                  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                  stroke-linecap: round;
                }
                @-webkit-keyframes rotate {
                  100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  }
                }

                @keyframes rotate {
                  100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  }
                }
                @-webkit-keyframes dash {
                  0% {
                    stroke-dasharray: 1,200;
                    stroke-dashoffset: 0;
                  }
                  50% {
                    stroke-dasharray: 89,200;
                    stroke-dashoffset: -35;
                  }
                  100% {
                    stroke-dasharray: 89,200;
                    stroke-dashoffset: -124;
                  }
                }
                @keyframes dash {
                  0% {
                    stroke-dasharray: 1,200;
                    stroke-dashoffset: 0;
                  }
                  50% {
                    stroke-dasharray: 89,200;
                    stroke-dashoffset: -35;
                  }
                  100% {
                    stroke-dasharray: 89,200;
                    stroke-dashoffset: -124;
                  }
                }
                @-webkit-keyframes color {
                  0% {
                    stroke: #505050;
                  }
                  40% {
                    stroke: #505050;
                  }
                  66% {
                    stroke: #505050;
                  }
                  80%, 90% {
                    stroke: #505050;
                  }
                }
                @keyframes color {
                  0% {
                    stroke: #505050;
                  }
                  40% {
                    stroke: #505050;
                  }
                  66% {
                    stroke: #505050;
                  }
                  80%, 90% {
                    stroke: #505050;
                  }
                }
              }
              &.checkbox {
                padding-left: 34px;
              }

              .ui_checkbox {
                position: absolute;
                top: 15.5px;
                left: 8px;
                margin-left: 2px;
                margin-right: 1px;
              }
            }

            &.gap {
              width: 25px;
              border-bottom: none;
            }

            &.td-regDate {
              text-align: right;

              .td-data {
                margin-right: 20px;
                padding-right: 6px;
              }
            }
          }
        }
      }
    }




  }
}
</style>
