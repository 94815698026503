class StorageFactory {
  static getInstance (storageType, serviceType, bucketName) {
    let Storage = null

    switch (storageType) {
      case 'local':
        break
      case 'aws-s3':
        Storage = require('./aws-s3')
        break
      default:
        throw new Error(`unsupported storage: ${storageType}`)
    }
    return new Storage(serviceType, bucketName)
  }
}

module.exports = StorageFactory
