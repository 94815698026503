<template>
  <div class="test-list">
    <div class="list-body">
      <div class="data-wrap">
        <div class="data-image"
             :ref="`dataImage-${testResultList.id}`">

          <img :src="testResultList.imagePath"
               alt="">
          <div class="data-ocr"
               v-if="testResultList.ocrResult">
            <div class="sentence"
                 v-for="(ocr, idx) in ocrResult"
                 :key="`ocr-${idx}`"
                 :style="{
                     width: (ocr[2]  * imageScale[testResultList.id]) + 'px',
                     height: (ocr[3] * imageScale[testResultList.id]) + 'px',
                     top: (ocr[1] * imageScale[testResultList.id]) + 'px',
                     left: (ocr[0] * imageScale[testResultList.id]) + 'px'
                   }"
                 :title="ocr.text">
            </div>
          </div>
        </div>
      </div>
      <div v-if="testResultList.ocrResult.data">
        <table class="ocr-table">
          <colgroup>
            <col width="10%" />
            <col/>
            <col/>
          </colgroup>
          <thead>
            <tr>
              <th>Id</th>
              <th>Image</th>
              <th>text</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ocr, idx) in ocrResult"
                :key="`crop-${idx}`">
              <td>{{Object.keys(ocrResult).indexOf(idx) + 1}}</td>
              <td>
                <crop-image :ref="`cropImage-${testResultList.id}`"
                    :url="testResultList.imagePath"
                    :imageWidth="1920"
                    :imageHeight="1080"
                    :width="ocr[2]"
                    :height="ocr[3]"
                    :offset="{ x: ocr[0], y: ocr[1] }"
                    :maxWidth="300"
                    :maxHeight="100">
                </crop-image>
              </td>
              <td>{{ocr[6]}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {TEST_RESULT_MAP} from '@/libs/constants'
import CropImage from '@/components/ui/CropImage.vue'
export default {
  name: 'TestResultList',
  props: {
    testResultList: {
      required: false,
      default: Object
    },
    imgDataId: {
      required: false,
      default: null
    }
  },
  components: {
    CropImage
  },
  watch: {
    testResultList () {
      this.calcScale()
    }
  },
  computed: {
    order () {
      return !this.listOption.sort.includes('-')
    },
    containerStyle (ocr, image) {
      let scale = 1
      const width = ocr[2]
      const height = ocr[3]
      const maxWidth = 300
      const maxHeight = 100
      const left = ocr[0]
      const top = ocr[1]
      const ratio = width / height
      const maxRatio = maxWidth / maxHeight

      if (ratio >= maxRatio && width > maxWidth) {
        scale = maxWidth / width
      } else if (ratio < maxRatio && height > maxHeight) {
        scale = maxHeight / height
      }
      const offsetX = (-left * scale) + 'px'
      const offsety = (-top * scale) + 'px'
      return {
        width: (this.width * scale) + 'px',
        height: (this.height * scale) + 'px',
        backgroundImage: `url(${image})`,
        backgroundPosition: `${offsetX} ${offsety}`,
        backgroundSize: `${this.getPercentage(1920, this.width)}%`
      }
    },
  },
  created () {
    window.addEventListener('resize', this.calcScale)
  },
  updated() {
    if(this.selectedImage !== this.imgDataId && this.$refs[`dataImage-${this.imgDataId}`]) {
      this.selectedImage = this.imgDataId
      this.calcScale()
    }
  },
  destroyed() {
    this.clearState()
  },
  methods: {
    clearState () {
      this.imageScale = {}
      this.imageSize = {}
      this.ocrResult = null
      this.first = false
    },
    calcScale (first) {
        const getImageData = () => {
          return new Promise(resolve => {
            if (this.testResultList.imagePath && this.$refs[`dataImage-${this.imgDataId}`]) {
              const img = new Image()
              img.onload = () => {
                this.imageSize[this.imgDataId] = {
                  width: this.$refs[`dataImage-${this.imgDataId}`].clientWidth,
                  height: this.$refs[`dataImage-${this.imgDataId}`].clientHeight
                }
                this.imageScale[this.imgDataId] = this.imageSize[this.imgDataId].width / img.width
                resolve(true)
              }
              img.src = this.testResultList.imagePath
            } else {
              resolve(false)
            }
          })
        }

        let promises = []
        promises.push(getImageData())

        Promise.all(promises)
            .finally(() => {
              const resultData = this.testResultList.ocrResult.data
              const ocrResult = {}
              for (const [key, value] of Object.entries(resultData)) {
                if (!ocrResult[key]) {
                  ocrResult[key] = []
                }
                for (const v of Object.values(value)) {
                  ocrResult[key].push(v)
                }
                ocrResult[key].sort((a, b) => parseFloat(b.conf) - parseFloat(a.conf))
              }
              this.ocrResult = ocrResult
              if (first) {
                this.first = first
              }
            })
    },
    resultValue(result) {
      return TEST_RESULT_MAP[result]
    }
  },
  data () {
    return {
      selectedImage: null,
      imageScale: {},
      imageSize: {},
      ocrResult: null,
      first: false,

      useHeaders: [
        {
          name: this.$t('testSetting.testKey'),
          // sortable: true,
          value: 'testKey'
        },
        {
          name: this.$t('testSetting.title'),
          // sortable: true,
          value: 'title'
        },
        {
          name: this.$t('testResult.scenario.result'),
          // sortable: true,
          value: 'result'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.test-list {
  position: relative;
  height: 100%;

  .list-body {
    z-index: 1;
    position: relative;
    height: calc(100% - 106px);
    .data-wrap {
      padding: 10px;
      border-radius: 10px;
      background-color: #151515;
    }

    .data-image {
      position: relative;

      img {
        width: 100%;
        margin-bottom: -7px;
        border: 1px solid rgba(255, 255, 255, 0.3);;
      }

      .data-ocr {
        .sentence {
          position: absolute;
          background: yellow;
          opacity: 0.3;
          border: 1px solid orange;
          transform: translate(-1px, -1px);

          //&:hover {
          //  opacity: 0;
          //}

          &.active {
            opacity: 0;
          }
        }

        .capture-highlight {
          position: absolute;
          outline: 1px solid #ffe30d;
          z-index: 1;
        }

        .capture-mask > div,
        .capture-guide > div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5)
        }

        .capture-guide > div {
          background-color: #ffe30d;
        }
      }
    }
    .ocr-table {
      width: 100%;
      margin-top: 33px;
      margin-bottom: 70px;

      th {
        font-size: 13px;
        font-weight: 500;
        color: #666666;
        border-top: 2px solid #666666;
        border-bottom: 1px solid #666666;
        padding: 7px 0;
        text-align: center;
      }

      td {
        padding: 15px 5px;
        font-size: 13px;
        vertical-align: top;
        color: #444444;
        word-break: break-all;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        transition: background-color 0.15s ease;
        cursor: pointer;

        img {
          max-width: 80%;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .colorchip {
          display: block;
          width: 28px;
          height: 28px;
          border: solid 1px rgba(0, 0, 0, 0.25);
          background-color: #886a4a;
          margin: 0 auto 2px;
          border-radius: 100%;
        }

        .nowrap {
          white-space: nowrap;
        }

        .align-right {
          text-align: right;
        }

        .align-left {
          text-align: left;
        }

        .code {
          background: #222222;
          color: #c1c1c1;
          font-family: monospace;
          text-align: left;
          padding: 5px 10px;
          margin: -5px 0;
          border-radius: 8px;
          overflow: auto;

          & /deep/ mark {
            background: #ffffff;
            color: #000000;
            font-family: monospace;
          }

          &::before {
            content: "···";
            text-align: center;
            color: #999999;
          }

          &::after {
            content: "···";
            text-align: center;
            color: #999999;
          }
        }
        textarea {
          width:100% !important;
          height:80px !important;
          box-sizing:border-box;
          line-height:1.2;
        }
        .advice-area {
          white-space: pre-wrap;
          font-family: monospace;
          text-align: left;
          padding: 5px 10px;
          margin: -5px 0;
          border-radius: 8px;
          border:1px solid #ccc;
          overflow: auto;
        }
        pre.list-code {
          white-space: pre-wrap;
          background: #222222;
          color: #c1c1c1;
          font-family: monospace;
          text-align: left;
          padding: 5px 10px;
          margin: -5px 0;
          border-radius: 8px;
          overflow: auto;
          min-height:80px;
        }
        pre.advice-area {
          min-height:80px;
        }
        .conf {
          display: inline-block;
          min-width: 32px;
          height: 18px;
          margin-bottom: 5px;
          border-radius: 3px;
          text-align: center;
          font-size: 12px;
          line-height: 18px;
        }

        button {
          line-height: 1;
          text-align: center;
          color: #444444;
          padding: 2px 4px;
          border-radius: 3px;
          margin: 0 1px;
          border: solid 1px #bbbbbb;
          color: #999999;
          background-color: #ffffff;
        }
      }

      .capture-wrap {
        height:100px;
        overflow:hidden;
        position:relative;
        border:1px solid #ccc;
      }
      .field-50 {
        width:50px;
      }
      .field-100 {
        width:100px;
      }

      tr:last-child {
        td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        }
      }

      .switch {
        position:relative;
        display:inline-block;
        width:45px;
        height:24px;
        input {
          opacity:0;
          width:0;
          height:0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top:0;
          left:0;
          right:0;
          bottom:0;
          border-radius: 24px;
          background-color: #ccc;
          -webkit-transition: .4s;
          transition: .4s;
        }
        .slider:before {
          position: absolute;
          content:'';
          width: 16px;
          height: 16px;
          left:4px;
          bottom:4px;
          border-radius: 50%;
          background-color: #fff;
          -webkit-transition: .4s;
          transition: .4s;
        }
        input:checked + .slider {
          background-color: #2196F3;
        }
        input:checked + .slider:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
        input:disabled + .slider {
          opacity: 0.3;
        }
      }
    }
  }
}

</style>
