import { request } from '@/libs/api-request'

export function ocrTest(params) {
  const url = 'ocr/upload'
  const config = {
    video: true,
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function splitVideo (params) {
  const url = 'ocr/split'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function uploadImg (params) {
  const url = 'ocr/upload_imgs'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getImgList () {
  const url = 'ocr/get_imgs'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function getResultList () {
  const url = 'ocr/get_results'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function getResultData (params) {
  const url = 'ocr/get_result_data'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function runImgOcr (params) {
  const url = 'ocr/run_ocr'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function ocrRunCheck (params) {
  const url = 'ocr/check_run'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function ocrSplitCheck (params) {
  const url = 'ocr/check_split'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}


export function toGPT (params) {
  const url = 'ocr/to_gpt'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
