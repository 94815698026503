<template>
  <header class="header--wrap">
    <div class="header">
      <h2 class="logo">
        <h2>OCR</h2>
      </h2>
    </div>
  </header>
</template>

<script>

export default {
  name: 'DefaultHeader',
  components: {

  },
  computed: {
  },
  data () {
    return {
      title: null
    }
  },
  created () {
    // EventBus.$on('setTitle', (value) => {
    //   this.title = value
    // })
  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.header--wrap {
  position: relative;
  z-index: 100;

  .header {
    position: relative;
    z-index: 1;
    height: 58px;
    padding-left: 0;

    .logo {
      vertical-align: top;
      display: inline-block;
      position: relative;
      margin: 15px 24px 15px 9px;
      z-index: 10;
      cursor: pointer;
      padding-left: 5px;

      .icon--logo-red {
        width: 112px;
        height: 35px;
        margin: -3px 0 0 3px;
      }

      h2 {
        margin-top: 5px;
        font-family: "rubrik-new";
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.22px;
        color: #ec0047;
      }
    }

    //.title--area {
    //  position: relative;
    //  vertical-align: top;
    //  display: inline-block;
    //  height: 47px;
    //  border-left: 1px solid #c1c1c1;
    //  font-size: 13px;
    //  white-space: nowrap;
    //
    //  h2 {
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    font-size: 22px;
    //    color: #444444;
    //    padding: 25px 0 0 15px;
    //    font-weight: 500;
    //
    //    /deep/ {
    //      .blue {
    //        color: #289eef;
    //
    //        em {
    //          display: inline-block;
    //          padding: 5px 6px;
    //          font-size: 12px;
    //          color: #289eef;
    //          border-radius: 5px;
    //          border: solid 1px #289eef;
    //          background-color: #d1ecff;
    //          margin-left: 8px;
    //          transform: translateY(-3px);
    //        }
    //      }
    //    }
    //  }
    //
    //  p {
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    padding: 19px 0 0 21px;
    //  }
    //
    //  /deep/ {
    //    .user-name {
    //      color: #444444;
    //      font-size: 14px;
    //    }
    //
    //    .plan-name {
    //      color: #ec0047;
    //    }
    //  }
    //}
  }
}
</style>
