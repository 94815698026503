<template>
  <div id="app">
    <transition name="layout">
      <component class="layout"
                 :is="layoutName"/>
    </transition>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'
import '@/style/app.scss'

export default {
  name: 'App',
  components: {
    'default-layout': DefaultLayout
  },
  computed: {
    layoutName () {
      if (!this.$route.matched.length) {
        return null
      }
      const route = Array.from(this.$route.matched).reverse()
          /* eslint-disable no-prototype-builtins */
          .find(item => item.meta.hasOwnProperty('layout'))
      const layout = route ? route.meta.layout : 'default'
      return `${layout}-layout`
    }
  }
}
</script>
<style lang="scss" scoped>
.layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: opacity 0.3s ease;
  opacity: 1;
}

.error-leave-active,
.error-enter-active {
  transition: transform 0.5s ease;
  transform: translateY(0);
  opacity: 1;
}

.error-enter,
.error-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
