<template>
  <transition name="fade"
              appear>
    <div class="dimmed">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DimmedLayer',
  mounted () {
    let body = document.querySelector('body')
    body.style.overflow = 'hidden'
  },
  destroyed () {
    let body = document.querySelector('body')
    body.style.overflow = 'initial'
  }
}
</script>

<style lang="scss" scoped="scoped">
.dimmed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000001;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  transition: opacity 0.3s ease;

  & > /deep/ * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.fade-enter-active,
  &.fade-leave-active {
    opacity: 1;
    transition: opacity 0.15s ease;
  }
  &.fade-enter,
  &.fade-leave-to{
    opacity: 0;
  }
}
</style>
