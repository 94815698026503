const AWS = require('aws-sdk')

const config = {
  accessKeyId: 'AKIA34F4S3OQ2QOLYG4I',
  secretAccessKey: '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',
  region: 'ap-northeast-2'
}

for (let [key, value] of Object.entries(config)) {
  if (!value) {
    throw new Error(`s3 invalid config: ${key}`)
  }
}

class S3Storage {
  constructor (serviceType, bucketName) {
    this.bucketName = bucketName
  }

  async putObject (key, data, isPublic = false) {
    let mimeType = null
    mimeType = 'application/octet-stream'

    const params = {
      Bucket: this.bucketName,
      Key: key,
      Body: data,
      ContentType: mimeType
    }

    if (isPublic) {
      params.ACL = 'public-read'
    }

    return new Promise((resolve, reject) => {
      try {
        const s3 = new AWS.S3(config)

        s3.upload(params, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      } catch (err) {
        reject(err)
      }
    })
  }
}

module.exports = S3Storage
