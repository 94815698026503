<template>
  <div id="defaultLayout"
       class="default_layout clearfix">
    <default-header id="defaultHeader"
                      class="header-wrap layout-transition-up"/>
<!--                      @onLoading="onLoading" />-->
    <div class="view-wrap layout-transition-fade">
      <router-view class="content-wrap" />
    </div>
  </div>
</template>

<script>
import DefaultHeader from '@/components/default/Header'
export default {
  name: 'DashboardLayout',
  components: {
    DefaultHeader
  },
  created () {
  },
  methods: {
  },
  computed: {

  },
  data () {
    return {
      onceLoaded: false,
      loading: true
    }
  }
}
</script>

<style lang="scss">
.default_layout {
  width: 100%;
  //min-width: 1250px;
  height: 100%;
  //min-height: 830px;
  background-color: #f4f4f1;

  .header-wrap {
    transition: transform 0.3s ease,
    margin-bottom 0.3s ease;
  }

  .view-wrap {
    height: calc(100% - 116px);
    transition: height 0.3s ease;

    .content-wrap {
      position: relative;
      height: 100%;
    }
  }

}

.loading-enter, .loading-leave-to{
  opacity: 0;
}
</style>
