<template>
  <span v-if="url" class="crop_image" :style="containerStyle">
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'CropImage',
  props: {
    url: String,
    width: Number,
    height: Number,
    imageWidth: Number,
    imageHeight: Number,
    offset: Object,
    maxWidth: {
      type: Number,
      default: 112
    },
    maxHeight: {
      type: Number,
      default: 60
    }
  },
  methods: {
    getPercentage (value, total, digits) {
      if (isNaN(value) || isNaN(total) || total === 0) {
        return 0
      }

      let result = value / total * 100

      if (digits !== undefined) {
        result = Number(result.toFixed(digits))
      }
      return result
    }
  },
  computed: {
    containerStyle () {
      let scale = 1
      const ratio = this.width / this.height
      const maxRatio = this.maxWidth / this.maxHeight

      if (ratio >= maxRatio && this.width > this.maxWidth) {
        scale = this.maxWidth / this.width
      } else if (ratio < maxRatio && this.height > this.maxHeight) {
        scale = this.maxHeight / this.height
      }

      const offsetX = (-this.offset.x * scale) + 'px'
      const offsety = (-this.offset.y * scale) + 'px'

      return {
        border: '1px solid #bbb',
        width: (this.width * scale) + 'px',
        height: (this.height * scale) + 'px',
        backgroundImage: `url(${this.url})`,
        backgroundPosition: `${offsetX} ${offsety}`,
        backgroundSize: `${this.getPercentage(this.imageWidth, this.width)}%`
      }
    }
  }
}
</script>

<style lang="scss">
.crop_image {
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  position: relative;
}
</style>
