import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nMessages from '@/data/i18n-messages'
import Cookies from 'js-cookie'
import { LOCALE_COOKIE } from './constants'

Vue.use(VueI18n)
let i18n

function traverseItem (data, container, locale, fallbackLocale) {
  for (const key of Object.keys(data)) {
    const value = data[key]

    if (typeof value !== 'object') {
      continue
    }

    if (typeof value[locale] === 'string') {
      container[key] = value[locale]
      continue
    } else if (value[locale] === undefined && typeof value[fallbackLocale] === 'string') {
      container[key] = value[fallbackLocale]
      continue
    }

    if (!container[key]) {
      container[key] = {}
    }
    traverseItem(value, container[key], locale, fallbackLocale)
  }
}

function getLocaleMessages (locale, fallbackLocale) {
  const messages = {}
  traverseItem(i18nMessages.messages, messages, locale, fallbackLocale)
  return messages
}

export function initI18n (locale, fallbackLocale = 'en') {
  const messages = {
    [locale]: getLocaleMessages(locale, fallbackLocale)
  }

  i18n = new VueI18n({
    locale,
    messages
  })
  return i18n
}

export function changeLocale (i18n, locale) {
  if (i18n.locale === locale) {
    return
  }
  setLocaleCookie(locale)
  window.location.reload()
}

export function getI18n () {
  return i18n
}

export function setLocaleCookie (value = 'en') {
  Cookies.set(LOCALE_COOKIE.name, value, {
    expires: LOCALE_COOKIE.expires,
    path: LOCALE_COOKIE.path,
    domain: LOCALE_COOKIE.domain
  })
}

export function getLocaleCookie () {
  return Cookies.get(LOCALE_COOKIE.name, {
    path: LOCALE_COOKIE.path,
    domain: LOCALE_COOKIE.domain
  })
}
