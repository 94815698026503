export const LOCALE_COOKIE = {
    name: 'locale',
    expires: 365,
    path: '/'
}

export const TEST_RESULT_MAP = {
    'failed': '실패',
    'error': '실행 에러',
    'passed': '성공',
    'skipped': '확인 필요'
}

export const STATUS_MAP = {
    1: '비디오 파일 업로드 중...',
    2: "비디오 파일 업로드 완료.",
    3: "비디오 분할 캡쳐 진행 중...",
    4: "비디오 분할 캡쳐 완료",
    5: "비디오 분할 캡쳐 업로드 중...",
    6: "비디오 분할 캡쳐 업로드 완료",
    101: "OCR 작업중...",
    102: "OCR 작업 완료."
}

export const STATUS_STOP = '대기 중'
