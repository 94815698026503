import { initI18n, getLocaleCookie, setLocaleCookie } from './i18n'

function initLocale () {
  const language = navigator.language || navigator.userLanguage
  let locale = getLocaleCookie()

  if (!locale) {
    if (language) {
      if (language.indexOf('ko') > -1) {
        locale = 'ko'
      } else if (language.indexOf('ja') > -1) {
        locale = 'ja'
      } else {
        locale = 'en'
      }
    } else {
      locale = 'en'
    }
    setLocaleCookie(locale)
  }

  document.documentElement.setAttribute('lang', locale)
  return locale
}

function loadWebFonts () {
  return new Promise((resolve) => {
    let webfont = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Noto+Sans+KR&family=Noto+Sans+JP'

    let link = document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('href', webfont)
    link.setAttribute('media', 'all')
    link.setAttribute('crossorigin', 'anonymous')

    let head = document.getElementsByTagName('head')[0]
    if (!head) {
      head = document.documentElement
    }
    head.insertBefore(link, head.lastChild)

    resolve()
  })
}

export default function () {
  const locale = initLocale()

  return loadWebFonts(locale).then(() => {
    return initI18n(locale)
  }).catch(err => {
    console.error(err)
  })
}
