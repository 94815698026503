<template>
  <div class="scenario-list">
    <div class="list-body">
      <div class="thead">
        <table>
          <colgroup>
            <col>
          </colgroup>
          <thead>
            <tr>
              <th v-for="column in columns"
                  :key="`tag-list-${column.text}-${column.value}`">
                {{ column.text }}
<!--                <button v-if="column.value === 'runAt'"-->
<!--                        class="sort"-->
<!--                        :class="{ active: scenarioList.length && computedSort === column.value,-->
<!--                                      asc: order,-->
<!--                                      desc: !order }">-->
<!--                  <i class="icon icon&#45;&#45;sort-asc"></i>-->
<!--                  <i class="icon icon&#45;&#45;sort-desc"></i>-->
<!--                </button>-->
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="tbody">
        <table>
          <tbody>
          <tr v-for="item in ocrList"
              :key="item.id"
              :class="{ active: dataId && item.id === dataId}"
              @click="getTestResultList(item.id)">
            <td><div class="td-data">{{item.fileName}}</div></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {format} from "date-fns";

export default {
  name: 'OcrList',
  props: ['ocrList'],
  components: {

  },
  created () {

  },
  computed: {
    computedSort () {
      return this.listOption.sort.replace('-', '')
    },
    order () {
      return !this.listOption.sort.includes('-')
    },
    columns () {
      return [
        {
          text: this.$t('testResult.scenario.result'),
          value: 'result'
        }
      ]
    }
  },
  watch: {
    // scenarioList (value){
    //   if (value.length > 0) {
    //     let lastScenarioId = value[0].id
    //     this.getTestResultList(lastScenarioId)
    //   }
    // }
  },
  methods: {
    changeSort (sort) {
      if (this.listOption.sort === '-' + sort) {
        this.listOption.sort = sort
      } else {
        this.listOption.sort = '-' + sort
      }
      this.sortInList()
    },
    sortInList () {
      let listData = this.ocrList
      listData.sort((a, b) => {
        if (typeof a[this.computedSort] === 'string' && typeof b[this.computedSort] === 'string') {
          if (this.order) {
            // return a[this.computedSort].toString().localeCompare(b[this.computedSort].toString())
            return new Intl.Collator('en').compare(a[this.computedSort].toString(), b[this.computedSort].toString())
          } else {
            // return b[this.computedSort].toString().localeCompare(a[this.computedSort].toString())
            return new Intl.Collator('en').compare(b[this.computedSort].toString(), a[this.computedSort].toString())
          }
        } else {
          if (this.order) {
            return a[this.computedSort] > b[this.computedSort] ? 1 : -1
          } else {
            return b[this.computedSort] > a[this.computedSort] ? 1 : -1
          }
        }
      })
    },
    dateTimeFormatter (datetime) {
      const dateObj = new Date(datetime)
      return format(dateObj, 'YYYY.MM.DD HH:mm:ss')
    },
    getTestResultList (dataId) {
      this.dataId = dataId
      this.$emit('clickResult', dataId)
    }
  },
  data () {
    return {
      dataId: null,
      listOption: {
        // pageNum: 1,
        // itemsPerPage: 50,
        sort: '-runAt'
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.scenario-list {
  position: relative;
  height: 100%;
  .list-body {
    z-index: 1;
    position: relative;
    height: calc(100% - 106px);

    .thead {
      height: 55px;
    }

    .tbody {
      min-height: 592px;
      position: relative;
      height: calc(100% - 67px);
      padding-bottom: 12px;
      overflow: auto;
      border-right: 1px solid #D1D1D1;
      table {
        display: block;
      }
    }

    table {
      position: relative;
      width: 100%;
      border-spacing: 0px;

      thead {
        tr {
          th {
            height: 39px;
            font-size: 13px;
            font-weight: normal;
            background: #375f7b;
            color: #ffffff;
            padding: 9px 32px 7px 21px;
            vertical-align: bottom;
            text-align: left;
            line-height: 20px;
            transition: background-color 0.15s ease;
            white-space: nowrap;

            .sort {
              display: inline-block;
              width: 14px;
              height: 18px;
              margin-top: -2px;
              margin-left: 2px;
              vertical-align: middle;
              border-radius: 4px;
              transition: background-color 0.15s ease,
              box-shadow 0.15s ease;

              i.icon {
                float: left;
                opacity: 0.2;
                transition: opacity 0.15s ease;
              }

              &.active {
                &.asc {
                  .icon--sort-asc {
                    opacity: 1;
                  }
                }

                &.desc {
                  .icon--sort-desc {
                    opacity: 1;
                  }
                }
              }

              &.active {
                background-color: #272727;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.146);
              }
            }

            &.th-numOfUrl {
              text-align: right;
            }

            &.hover {
              .sort {
                background-color: #272727;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.146);
              }
            }

            &:first-child {
              border-radius: 7px 0 0 7px;
            }
          }
        }
      }

      tbody {
        display: block;
        width: 100%;

        tr {
          display: table;
          position: relative;
          width: 100%;

          &:hover {
            td {
              .td-data {
                background-color: #ebebeb;
              }
            }
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            left: 5px;
            right: 15px;
            z-index: -1;
            border-radius: 5px;
            height: calc(100% - 14px);
            transition: box-shadow 0.2s ease;
          }
          &.active {
            &::after {
              box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.2), inset 0 0 0 2px #ea0046;
            }

            td {
              .td-data {
                background-color: transparent;
              }
            }
          }

          td {
            text-align: left;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.31;
            letter-spacing: normal;
            color: #666;
            word-break: break-all;
            cursor: pointer;
            vertical-align: top;
            border-bottom: 1px solid #ebebeb;
            padding-right: 0px;
            padding-left: 0px;

            .td-data {
              position: relative;
              min-height: 20px;
              padding: 11px 11px 9px;
              margin: 7px 0;
              cursor: pointer;

              &.result {
                display: flex;

                .count {
                  padding: 0px 5px;
                  &.passCount {
                    color: #56B527;
                  }

                  &.failCount {
                    color: #E92020;
                  }

                  &.skippedCount {
                    color: #FF8400;
                  }

                  &.errCount {
                    color: #888888;
                  }
                }
              }
            }

            &:first-child {
              .td-data {
                border-radius: 5px 0 0 5px;
              }
            }

            &:last-child {
              .td-data {
                border-radius: 0 5px 5px 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
